import { Link } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { ArrowRight } from 'react-feather'
import { extractPathFromWordpressUrl, trunc } from '../utils/helpers'

const MAX_DESCRIPTION_LENGTH = 314

function shortenDescription(description, length = MAX_DESCRIPTION_LENGTH) {
  return trunc(description, length)
}

const CARD_VARIANTS = ['featured']

const Solution = ({
  solution: {
    id,
    name,
    isFeatured,
    category,
    manufacturer,
    description,
    featuredPage,
    image,
    ...rest
  },
  variant = 'none',
  displayFeaturedTag = false,
}) => {
  const [expanded, setExpanded] = useState(false)
  const isDescriptionLong = description.length > MAX_DESCRIPTION_LENGTH

  switch (variant) {
    case 'featured':
      return (
        <div className="card--default">
          <div className="flex-shrink-0">
            <Link
              to={extractPathFromWordpressUrl(featuredPage)}
              className="block mb-0"
              data-testid="featured-solution"
            >
              <div className="relative">
                {image && (
                  <Img
                    fluid={image.localFile.childImageSharp.fluid}
                    className="h-64 w-full object-cover"
                    alt=""
                    style={{
                      borderTopLeftRadius: '0.5em',
                      borderTopRightRadius: '0.5em',
                    }}
                  />
                )}
              </div>
            </Link>
          </div>
          <div className="p-8 flex flex-col h-full justify-between space-y-2">
            <div>
              <h4 className="mb-2">{name}</h4>
              <div className="horizontal-list mb-2">
                {category && (
                  <span className="tag is-primary">
                    <strong className="has-text-white">{category}</strong>
                  </span>
                )}
                {manufacturer && (
                  <span className="tag is-dark">
                    <strong className="has-text-white">{manufacturer}</strong>
                  </span>
                )}
              </div>
              <p className="description mb-0">
                {expanded ? description : shortenDescription(description)}
              </p>
              {isDescriptionLong && !isFeatured && (
                <div>
                  <span
                    role="button"
                    tabIndex="-1"
                    className="expand-text-btn"
                    onKeyDown={() => {}}
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded ? 'Show less' : 'Read more'}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    default:
      return (
        <div className="card--default">
          <div className="p-8">
            {displayFeaturedTag && isFeatured && (
              <span className="tag is-primary mb-2">
                <strong className="has-text-white">Featured</strong>
              </span>
            )}
            <h4 className="mb-2">{name}</h4>
            <div className="horizontal-list">
              {category && (
                <span className="tag is-primary mb-2">
                  <strong className="has-text-white">{category}</strong>
                </span>
              )}
              {manufacturer && (
                <span className="tag is-dark mb-2">
                  <strong className="has-text-white">{manufacturer}</strong>
                </span>
              )}
            </div>
            <p
              className={`description ${
                isDescriptionLong && !isFeatured ? 'mb-0' : ''
              }`}
            >
              {expanded ? description : shortenDescription(description)}
            </p>
            {isDescriptionLong && !isFeatured && (
              <div>
                <span
                  role="button"
                  tabIndex="-1"
                  className="expand-text-btn"
                  onKeyDown={() => {}}
                  onClick={() => setExpanded(!expanded)}
                >
                  {expanded ? 'Show less' : 'Read more'}
                </span>
              </div>
            )}
            {isFeatured && (
              <Link
                to={extractPathFromWordpressUrl(featuredPage)}
                className="button is-style-outline "
              >
                <strong className="pr-4">Read More</strong>
                <ArrowRight size={20} />
              </Link>
            )}
          </div>
        </div>
      )
  }
}

Solution.propTypes = {
  /**
   * Displays featured tag if set to true
   */
  displayFeaturedTag: PropTypes.bool,

  /**
   * Specify variant of card here
   */
  variant: PropTypes.oneOf(CARD_VARIANTS),
}

export default Solution
